import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Banner, { BannerBackgroundColor, BannerVariant } from 'components/Banner';
import FragranceInfo from 'components/FragranceInfo';
import IngredientsSection from 'containers/IngredientsSection';

const { SIMPLE_PRIMARY } = BannerVariant;
const { GREEN } = BannerBackgroundColor;

const OurSciencePage: FC<OurSciencePageTypes.OurScienceProps> = ({
  data: {
    ourScience: {
      urls,
      seo,
      banner,
      extraInformationBlock,
      title: ingredientsTitle,
      description: ingredientsDescription,
      footnote,
      whatWeUseBlock,
    },
  },
}) => {
  const { title, keywords, description } = seo;

  const ingredientsSectionProps = {
    title: ingredientsTitle,
    description: ingredientsDescription,
    whatWeUseBlock,
    footnote,
  };

  return (
    <Layout>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Banner variant={SIMPLE_PRIMARY} backgroundColor={GREEN} {...banner} />
      <IngredientsSection {...ingredientsSectionProps} />
      <FragranceInfo {...extraInformationBlock} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    ourScience(lang: { eq: $lang }) {
      ...OurSciencePageFragment
    }
  }
`;

export default OurSciencePage;
