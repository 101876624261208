import React, { FC } from 'react';

import { Container } from 'layout';
import Typography, { TypographyProps } from 'common/Typography';
import Accordion, { AccordionVariant } from 'components/Accordion';

import { IngredientsSectionProps } from './models.d';

import './IngredientsSection.scss';

const { TERTIARY } = AccordionVariant;
const VISIBLE_ITEMS_COUNT = 2;

const IngredientsSection: FC<IngredientsSectionProps> = ({
  title,
  description,
  footnote,
  whatWeUseBlock,
}) => {
  const renderIngredientsBlock = whatWeUseBlock.map(
    ({ description: ingredients, title: ingredientsTitle, showLessText, showMoreText }) => {
      const splittedIngredients = ingredients
        .split('</ul>')
        .map((ingredient): { value: string } => ({ value: `${ingredient}</ul>` }))
        .slice(0, -1);

      const accordionButton = { openText: showMoreText, closedText: showLessText };

      const listItemProps: TypographyProps = {
        size: 16,
        padding: { bottom: 'xxs' },
      };

      const visibleContent = (): JSX.Element | null => {
        if (!splittedIngredients) return null;

        const renderContent = splittedIngredients
          ?.slice(0, VISIBLE_ITEMS_COUNT)
          .map(({ value }) => (
            <li key={value} className="ingredients-section__list-item">
              <Typography {...listItemProps} dangerouslySetInnerHTML={value} />
            </li>
          ));

        return <ul className="ingredients-section__list">{renderContent}</ul>;
      };

      const hiddenContent = (): JSX.Element | null => {
        if (!splittedIngredients) return null;

        const renderContent = splittedIngredients?.slice(VISIBLE_ITEMS_COUNT).map(({ value }) => (
          <li key={value} className="ingredients-section__list-item">
            <Typography {...listItemProps} dangerouslySetInnerHTML={value} />
          </li>
        ));

        return <ul className="ingredients-section__list">{renderContent}</ul>;
      };

      return (
        <div className="ingredients-section__ingredients">
          <Typography size={{ base: 30, md: 35 }} weight="bold">
            {ingredientsTitle}
          </Typography>
          <Accordion
            className="ingredients-section__accordion"
            variant={TERTIARY}
            button={accordionButton}
            visibleContent={visibleContent()}
            hiddenContent={hiddenContent()}
          />
        </div>
      );
    }
  );

  return (
    <Container className="ingredients-section" data-testid="ingredients-section-item">
      <Typography
        as="h2"
        size={{ base: 30, md: 35 }}
        padding={{ bottom: { base: 'lg', md: 'xl' } }}
        align="center"
        className="ingredients-section__title"
      >
        {title}
      </Typography>
      <Typography
        size={{ base: 18, md: 25 }}
        align="center"
        weight="bold"
        dangerouslySetInnerHTML={description}
        className="ingredients-section__subtitle"
      />
      {renderIngredientsBlock}
      <Typography
        size={{ base: 18, md: 20 }}
        align="center"
        className="ingredients-section__footnote"
        dangerouslySetInnerHTML={footnote}
      />
    </Container>
  );
};

export default IngredientsSection;
