import React, { FC } from 'react';

import { Container } from 'layout';
import Image from 'common/Image';
import Typography from 'common/Typography';

import { FragranceInfoProps } from './models';

import './FragranceInfo.scss';

const FragranceInfo: FC<FragranceInfoProps> = ({ title, subtitle, description, image }) => (
  <Container className="fragrance-info" data-testid="fragrance-info">
    <Typography
      className="fragrance-info__title"
      size={{ base: 30, md: 42 }}
      align="center"
      as="h2"
    >
      {title}
    </Typography>
    <div className="fragrance-info__wrapper">
      <Image {...image} className="fragrance-info__image" />
      <div className="fragrance-info__container">
        <Typography
          className="fragrance-info__subtitle"
          size={{ base: 24, md: 35 }}
          lineHeight="small"
          weight="bold"
          padding={{ top: { base: 'sm', md: 'xxs' } }}
          dangerouslySetInnerHTML={subtitle}
        />
        <Typography
          className="fragrance-info__description"
          size={{ base: 18, md: 20 }}
          padding={{ top: { base: 'xxs', md: 'sm' }, bottom: { base: 'xxs', md: 'sm' } }}
          dangerouslySetInnerHTML={description}
        />
      </div>
    </div>
  </Container>
);

export default FragranceInfo;
